import {
  FC,
  lazy,
  PropsWithChildren,
  Suspense,
  useCallback,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import PresenceProvider from 'src/contexts/PresenceProvider';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import { useInsightsSearchParams } from '../util/useInsightsSearchParams';
import {
  InsightsDetailContextParams,
  InsightsDetailContextProvider,
} from './context';

const InsightsDetailModal = lazy(
  () => import('./components/InsightsDetailModal/InsightsDetailModal')
);

type Props = PropsWithChildren;

export const InsightsDetailOutlet: FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { getParsedValues } = useInsightsSearchParams();
  const { timePeriod } = getParsedValues();

  const { accountUuid: insightsAdAccountFacebookUuid } = useParams();
  const insightsUuid = searchParams.get('insightsUuid');

  const user = useUserAndOrganisation();

  const [state, setState] = useState<InsightsDetailContextParams | null>(null);

  const show = useCallback(
    (params: InsightsDetailContextParams) => {
      const queryString = window.location.search;
      const updatedSearchParams = new URLSearchParams(queryString);
      updatedSearchParams.set('insightsUuid', params.uuid);
      setState(params);
      setSearchParams(updatedSearchParams);
    },
    [setSearchParams]
  );

  const close = useCallback(() => {
    if (insightsUuid) {
      // Replace the current history item with just `?insightsUuid=...` so that
      // the users history will direct them to the ad with no other params set.
      const baseParams = new URLSearchParams({ insightsUuid: insightsUuid });
      history.replaceState(
        null,
        '',
        `${window.location.pathname}?${baseParams.toString()}`
      );
    }
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete('insightsUuid');
    updatedSearchParams.delete('carousel-asset-uuid');
    // Push the cleared query params to the history stack, important we push and not replace so that back button returns user to ad
    setSearchParams(updatedSearchParams);
    setState(null);
  }, [searchParams, setSearchParams, insightsUuid]);

  const adQuery = trpc.insights.getFacebookAdWithInsights.useQuery(
    {
      uuid: insightsUuid ?? '',
      insightsAdAccountFacebookUuid: insightsAdAccountFacebookUuid ?? '',
      timePeriod,
    },
    {
      enabled: !!insightsUuid && !!insightsAdAccountFacebookUuid,
    }
  );

  const data =
    adQuery.data ??
    (state?.data?.ad.uuid === insightsUuid ? state.data.ad : null);

  const nextAd = state?.data?.pagination?.getAdAtFlatIndex(
    state.data.pagination.flatIndex + 1
  );

  const prevAd = state?.data?.pagination?.getAdAtFlatIndex(
    state.data.pagination.flatIndex - 1
  );

  const next = nextAd
    ? () => {
        const queryString = window.location.search;
        const updatedSearchParams = new URLSearchParams(queryString);
        updatedSearchParams.set('insightsUuid', nextAd.ad.uuid);
        setSearchParams(updatedSearchParams);
        setState((s) => ({
          uuid: nextAd.ad.uuid,
          data: {
            ad: nextAd.ad,
            pagination: s?.data?.pagination
              ? {
                  flatIndex: s?.data?.pagination?.flatIndex + 1,
                  getAdAtFlatIndex: s.data?.pagination?.getAdAtFlatIndex,
                }
              : null,
          },
        }));
      }
    : null;

  const prev = prevAd
    ? () => {
        const queryString = window.location.search;
        const updatedSearchParams = new URLSearchParams(queryString);
        updatedSearchParams.set('insightsUuid', prevAd.ad.uuid);
        setSearchParams(updatedSearchParams);
        setState((s) => ({
          uuid: prevAd.ad.uuid,
          data: {
            ad: prevAd.ad,
            pagination: s?.data?.pagination
              ? {
                  flatIndex: s?.data?.pagination?.flatIndex - 1,
                  getAdAtFlatIndex: s.data?.pagination?.getAdAtFlatIndex,
                }
              : null,
          },
        }));
      }
    : null;

  return (
    <InsightsDetailContextProvider value={show}>
      {!!insightsUuid && !!insightsAdAccountFacebookUuid && !!data && (
        <Suspense>
          {/** Note: we use organisation permissions to build presences here
           because we want to allow tagging in Comments prior to saving an ad
           this is a special case */}
          <PresenceProvider
            entityType="Organisation"
            entityUuid={user.data?.organisation.uuid}
          >
            <InsightsDetailModal
              ad={data}
              insightsAdAccountFacebookUuid={insightsAdAccountFacebookUuid}
              next={next}
              prev={prev}
              afterClose={close}
            />
          </PresenceProvider>
        </Suspense>
      )}
      {children}
    </InsightsDetailContextProvider>
  );
};
