import { FC, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import {
  Dialog,
  Heading,
  ListBox,
  ListBoxItem,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { AriaBaseButton } from 'src/components/Button/BaseButton';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { AdAccount } from '../../lib/facebook';

export type PendingAuthState = {
  currentIntegrations: string[];
  adAccounts: AdAccount[];
  fbUserID: string;
  accessToken: string;
};

type FacebookAdAccountSelectModalProps = {
  pendingAuthState: PendingAuthState | null;
  onOpenChange: (isOpen: boolean) => void;
};

export const FacebookAdAccountSelectModal: FC<
  FacebookAdAccountSelectModalProps
> = ({ pendingAuthState, onOpenChange }) => {
  return (
    <ModalOverlay
      isOpen={!!pendingAuthState}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-500 flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60 p-4 text-center"
    >
      <Modal className="w-full max-w-md overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl">
        <Dialog className="relative outline-none">
          {({ close }) =>
            pendingAuthState ? (
              <FacebookAdAccountSelect
                pendingAuthState={pendingAuthState}
                close={close}
              />
            ) : null
          }
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

type FacebookAdAccountSelectProps = {
  close: () => void;
  pendingAuthState: PendingAuthState;
};

const FacebookAdAccountSelect: FC<FacebookAdAccountSelectProps> = ({
  close,
  pendingAuthState,
}) => {
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();

  const [selectedAdAccountIds, setSelectedAdAccountIds] = useState<string[]>(
    Array.from(new Set(pendingAuthState.currentIntegrations))
  );

  const queryClient = useQueryClient();

  const connectFacebook =
    trpc.integrations.updateFacebookIntegration.useMutation({
      onSuccess: (result) => {
        void queryClient.invalidateQueries(
          getQueryKey(trpc.integrations.getIntegrations, undefined, 'query')
        );

        void queryClient.invalidateQueries(
          getQueryKey(
            trpc.insights.getManyFacebookAdAccounts,
            undefined,
            'query'
          )
        );

        void queryClient.invalidateQueries(
          getQueryKey(trpc.insights.getFacebookAdAccount, undefined, 'query')
        );

        const connections = Object.entries(result).reduce<{
          success: { adAccountId: string; name: string }[];
          fail: { adAccountId: string; name: string; reason: string }[];
        }>(
          (acc, [key, value]) => {
            if (value.status === 'success') {
              acc.success.push({ adAccountId: key, name: value.name });
            }

            if (value.status === 'failure') {
              acc.fail.push({
                adAccountId: key,
                name: value.name,
                reason: value.reason,
              });
            }

            return acc;
          },
          { success: [], fail: [] }
        );

        if (connections.success.length) {
          const details = connections.success
            .map((connection) => {
              void recordEvent({
                action: 'Connected',
                target: 'AdAccount',
                metadata: {
                  accountId: connection.adAccountId,
                  name: connection.name,
                },
              });

              return connection.name;
            })
            .join(', ');

          toast.success(
            LL.integrations.facebook.connectFacebook.success({ details }),
            {
              className: 'toast-success',
              autoClose: 7000,
            }
          );
        }

        if (connections.fail.length) {
          connections.fail.forEach((connection) => {
            const details = `${connection.name} (${connection.adAccountId}): ${connection.reason}`;
            toast.info(
              LL.integrations.facebook.connectFacebook.fail({
                details,
              }),
              { className: 'toast-danger', autoClose: 7000 }
            );
          });
        }

        close();
      },
      onError: (e) => {
        toast.error(
          LL.integrations.facebook.connectFacebook.error({
            error: 'message' in e ? e.message : 'Unknown error',
          }),
          { className: 'toast-danger' }
        );
      },
    });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-auto flex-row justify-between gap-4 text-purple-800">
        <Heading className="truncate text-xl font-bold">
          Select Facebook Ad Accounts
        </Heading>

        <AriaBaseButton
          onPress={close}
          icon={
            <Icon>
              <XClose />
            </Icon>
          }
        />
      </div>
      <div className="flex h-96 min-w-[320px] flex-col justify-between gap-4">
        <div className="grid flex-auto grid-cols-1 grid-rows-3 gap-4 overflow-hidden">
          <div className="row-span-1 flex flex-col gap-2">
            <Heading className="truncate text-base font-semibold text-purple-800">
              Selected Accounts
            </Heading>
            <div className="h-min flex-row flex-wrap gap-2">
              {selectedAdAccountIds.length === 0 ? (
                <span className="text-sm text-purple-800/50">
                  {LL.noneSelected()}
                </span>
              ) : (
                <ListBox
                  items={pendingAuthState.adAccounts.filter((x) =>
                    selectedAdAccountIds.includes(x.accountId)
                  )}
                  aria-label="Selected Facebook ad accounts"
                  layout="stack"
                  orientation="horizontal"
                  className="flex max-h-16 flex-row flex-wrap gap-2 overflow-y-auto"
                >
                  {({ name, accountId }) => (
                    <ListBoxItem
                      className="flex flex-row items-center gap-2 rounded-full border border-solid border-purple-300 px-2 py-1 text-xs font-semibold text-purple-800"
                      id={accountId}
                      key={accountId}
                    >
                      <span>{name}</span>
                      <AriaBaseButton
                        onPress={() =>
                          setSelectedAdAccountIds((selectedAccountIds) => {
                            const idx = selectedAccountIds.findIndex(
                              (selectedAccountId) =>
                                selectedAccountId === accountId
                            );

                            return idx !== -1
                              ? [
                                  ...selectedAccountIds.slice(0, idx),
                                  ...selectedAccountIds.slice(idx + 1),
                                ]
                              : selectedAccountIds;
                          })
                        }
                        icon={
                          <Icon className="h-3 w-3">
                            <XClose />
                          </Icon>
                        }
                      />
                    </ListBoxItem>
                  )}
                </ListBox>
              )}
            </div>
          </div>
          <div className="row-span-2 flex flex-col gap-2">
            <Heading className="truncatel text-base font-semibold leading-6 text-purple-800">
              Available Ad Accounts
            </Heading>
            <AriaMenu.List
              className="overflow-auto"
              items={pendingAuthState.adAccounts}
              onSelectionChange={(set) => {
                setSelectedAdAccountIds(Array.from(set as Set<string>));
              }}
              selectionMode="multiple"
              selectedKeys={selectedAdAccountIds}
            >
              {(item) => (
                <AriaMenu.Item
                  id={item.accountId}
                  key={item.accountId}
                  checkboxClassName={
                    'border-2 border-solid border-purple-300 rounded text-white group-selected:bg-purple-800 group-selected:border-purple-800'
                  }
                  showCheckbox={true}
                >
                  {item.name}
                </AriaMenu.Item>
              )}
            </AriaMenu.List>
          </div>
        </div>
        <div className="flex flex-row gap-2 [&>*]:flex-auto">
          <AriaButton
            isDisabled={connectFacebook.isLoading}
            variant="secondary"
            onPress={close}
          >
            {LL.cancel()}
          </AriaButton>
          <AriaButton
            loading={connectFacebook.isLoading}
            onPress={() => {
              const adAccounts = pendingAuthState.adAccounts.filter((x) =>
                selectedAdAccountIds.includes(x.accountId)
              );
              connectFacebook.mutate({
                fbUserID: pendingAuthState.fbUserID,
                accessToken: pendingAuthState.accessToken,
                adAccounts: adAccounts,
              });
            }}
            isDisabled={selectedAdAccountIds.length === 0}
          >
            {LL.confirm()}
          </AriaButton>
        </div>
      </div>
    </div>
  );
};
