import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'src/components/Icon';
import ArrowLeft from 'src/assets/svgicons/line/arrow-left.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import Navigation from 'src/components/Navigation';
import BaseLibraryToolbar from 'src/pages/LibraryV2/components/LibraryToolbar/components/BaseLibraryToolbar/BaseLibraryToolbar';
import LibraryGrid from '../LibraryGrid/LibraryGrid';
import Avatar from '../Misc/Avatar';
import { SquareLoaders } from '../SquareLoaders';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';

export const AppWithNavLoader: React.FunctionComponent = (): JSX.Element => {
  const user = useUserAndOrganisation();

  return (
    <div className="relative flex flex-col h-full">
      {user.data ? (
        <Navigation />
      ) : (
        <Navigation.Base mobileMenu={null}>
          <div className="flex flex-row items-center">
            <Navigation.DefaultLead />
            <div className="hidden flex-row gap-2 items-center px-6 lg:flex">
              <Navigation.SkeletonLinks />
            </div>
          </div>

          <Navigation.Section>
            <Avatar className="h-9 w-9" />
          </Navigation.Section>
        </Navigation.Base>
      )}
      <div className="flex w-full grow items-center justify-center">
        <Icon className="text-purple-800 animate-pulse">
          <MagicBriefBolt />
        </Icon>
      </div>
    </div>
  );
};

export const SubRouteLoader: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className="flex w-full grow items-center justify-center">
      <Icon className="animate-pulse text-purple-800">
        <MagicBriefBolt />
      </Icon>
    </div>
  );
};

export const AuthenticationLoader: React.FunctionComponent =
  (): JSX.Element => {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white animate-pulse px-2">
        <div className="flex flex-col gap-5 max-w-md w-full">
          <div className="w-full h-10 bg-purple-100 rounded-lg" />
          <div className="flex flex-col items-center gap-1">
            <div className="w-[calc(100%-2rem)] h-4 bg-purple-100 rounded-lg" />
            <div className="w-[calc(100%-2.5rem)] h-4 bg-purple-100 rounded-lg" />
          </div>
          <div className="w-full h-10 bg-purple-100 rounded-lg" />
          <div className="w-full h-10 bg-purple-100 rounded-lg -mb-2" />
          <div className="w-full h-10 bg-purple-100 rounded-lg" />
          <div className="w-full h-3 bg-purple-100 rounded-lg place-self-center" />
          <div className="w-1/3 h-10 bg-purple-100 rounded-lg place-self-center mb-4" />
          <div className="w-1/2 h-14 bg-purple-100 rounded-lg place-self-center" />
        </div>
      </div>
    );
  };

export const LibraryLoader: React.FunctionComponent<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }): JSX.Element => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="h-10 flex items-center">
              <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
            </div>
          }
        />
      )}
      <div className="py-4 px-5 md:px-9">
        <LibraryGrid isLoading isFetching={false} />
      </div>
    </div>
  );
};

export const StoryboardsLoader: React.FunctionComponent<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }): JSX.Element => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
          }
        />
      )}
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(32rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const InsightsLoader: FC = () => {
  return (
    <div className="relative flex min-w-0 grow flex-col py-4 px-5 md:px-9">
      <LibraryGrid isLoading isFetching={false} />
    </div>
  );
};

export const SlackLoader: FC = () => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      <BaseLibraryToolbar
        actions={null}
        mobileMenu={null}
        className="top-[72px]"
        title={
          <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
        }
      />
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(24rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const BriefsLoader: React.FunctionComponent<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }) => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="bg-primary/10 rounded-lg animate-pulse w-32 h-6" />
          }
        />
      )}
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(24rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const BriefEditorLoader: React.FunctionComponent = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="relative flex flex-col h-full">
      <Navigation.Base mobileMenu={null}>
        <Navigation.Section>
          <Navigation.Button
            onClick={() => {
              if (location.key === 'default') {
                navigate('/briefs', { replace: true });
              } else {
                navigate(-1);
              }
            }}
            icon={
              <Icon>
                <ArrowLeft />
              </Icon>
            }
          />
        </Navigation.Section>
        <div className="w-32 text-white rounded-lg bg-white/10 animate-pulse" />
        <Navigation.Section />
      </Navigation.Base>
      <div className="flex w-full flex-auto">
        <div className="flex w-full flex-auto flex-col bg-white px-4 py-8 sm:px-10">
          <div className="flex flex-col gap-4">
            <SquareLoaders amount={20} className="h-5.5" />
          </div>
        </div>
      </div>
    </div>
  );
};
