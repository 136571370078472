import { inferProcedureInput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import * as Sentry from '@sentry/react';
import { useDebouncedCallback } from 'use-debounce';
import { trpcProxyClient } from 'src/lib/trpc';
import { getAnonymousId } from './user';

async function recordEvent(
  data: Omit<
    inferProcedureInput<AppRouter['userEvents']['userState']>,
    'anonymousIdentifier'
  >
) {
  try {
    const anonymousId = getAnonymousId();
    await trpcProxyClient.userEvents.userState.mutate({
      ...data,
      anonymousIdentifier: anonymousId,
      metadata: {
        ...data.metadata,
        current_url: window.location.pathname,
      },
    });
  } catch (e) {
    Sentry.captureException(e, (scope) => {
      scope.setTransactionName('Recording analytics event');
      scope.setExtras(data);
      return scope;
    });
  }
}

export default function useNewAnalyticsEvent() {
  const debouncedRecordEvent = useDebouncedCallback(recordEvent, 1000);

  return { recordEvent, debouncedRecordEvent };
}
