import { cva, VariantProps } from 'class-variance-authority';
import {
  Button,
  type ButtonProps as AriaButtonProps,
} from 'react-aria-components';
import { cn } from 'src/lib/cn';
import Spinner from '../Loaders/Spinner';

const buttonVariants = cva(
  [
    /* Base */
    'inline-flex w-full items-center justify-center gap-2 whitespace-nowrap rounded-lg transition-colors duration-300 ease-out',
    /* Typography */
    'text-sm font-semibold tracking-[0.08px]',
    /* Disabled */
    'disabled:pointer-events-none',
    /* Resets */
    'focus-visible:outline-none',
  ],
  {
    variants: {
      variant: {
        primary: [
          /* Base */
          'bg-primary text-white shadow-[0_1px_3px_0_rgba(41,14,131,0.05),0_1px_2px_0_rgba(41,14,131,0.06)]',
          /* Hover */
          'hover:bg-purple-900',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#E3DBFF]',
          /* Disabled */
          'disabled:bg-purple-300',
        ],
        secondary: [
          /* Base */
          'border border-purple-200 bg-white text-primary shadow-[0_1px_3px_0_rgba(41,14,131,0.05),0_1px_2px_0_rgba(41,14,131,0.06)]',
          /* Hover */
          'hover:bg-purple-50',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#EEE9FF]',
          /* Disabled */
          'disabled:text-purple-300',
        ],
        text: [
          /* Base */
          'text-primary',
          /* Hover */
          'hover:bg-purple-50',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#EEE9FF]',
          /* Disabled */
          'disabled:text-purple-300',
        ],
        cta: [
          /* Base */
          'bg-gradient-to-b from-[#AE62D2] via-[#934ACC] to-[#5F36EF] text-white shadow-[0_1px_3px_0_rgba(41,14,131,0.05),0_1px_2px_0_rgba(41,14,131,0.06)]',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#E3DBFF]',
          /* Disabled */
          'disabled:bg-purple-300 disabled:bg-none',
        ],
        destructive: [
          /* Base */
          'border border-red-200 bg-red-100 text-red-700 shadow-[0_1px_3px_0_rgba(41,14,131,0.05),0_1px_2px_0_rgba(41,14,131,0.06)]',
          /* Hover */
          'hover:bg-red-200',
          /* Focus */
          'focus-visible:border-red-700 focus-visible:shadow-[0_0_0_4px_#FEE2E2]',
          /* Disabled */
          'disabled:border-red-300 disabled:bg-red-50 disabled:text-red-300',
        ],
      },
      size: {
        small: 'h-9 px-3 py-2',
        medium: 'h-10 px-3.5 py-2.5',
        large: 'h-12 px-4 py-3',
      },
    },
  }
);

interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof buttonVariants> {
  variant?: 'primary' | 'secondary' | 'text' | 'cta' | 'destructive';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
}

const MagicBriefButton = ({
  className,
  variant = 'primary',
  size = 'medium',
  loading = false,
  children,
  ...props
}: ButtonProps) => {
  return (
    <Button
      className={cn(buttonVariants({ variant, size }), className)}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </Button>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export { MagicBriefButton, buttonVariants };
export type { ButtonProps };
