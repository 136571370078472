import { createTRPCReact, httpBatchLink, httpLink } from '@trpc/react-query';
import { createTRPCProxyClient, splitLink } from '@trpc/client';
import superjson from 'superjson';
import { appConfigs } from 'src/configs';
import { auth } from './firebase';
import type { AppRouter } from '@magicbrief/server/src/trpc/router';

/** @todo remove when we migrate endpoint */
const getUrl = () => {
  const betaUrl = localStorage.getItem('beta:MB_API_URL');
  return betaUrl != null ? `${betaUrl}/trpc` : appConfigs.trpcHost ?? '';
};

export const trpc = createTRPCReact<AppRouter>();

export const trpcProxyClient = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: getUrl(),
      async headers() {
        const firebaseToken = await auth.currentUser?.getIdTokenResult();
        const authToken = `Bearer ${firebaseToken?.token}`;
        if (!firebaseToken) {
          return {};
        }
        return {
          Authorization: authToken,
        };
      },
    }),
  ],
});

export const trpcReactClient = trpc.createClient({
  transformer: superjson,
  links: [
    splitLink({
      condition(op) {
        // check for context property `skipBatch`
        return op.context.skipBatch === true;
      },
      // when condition is true, use normal request
      true: httpLink({
        url: getUrl(),
        async headers() {
          const firebaseToken = await auth.currentUser?.getIdTokenResult();
          const authToken = `Bearer ${firebaseToken?.token}`;
          if (!firebaseToken) {
            return {};
          }
          return {
            Authorization: authToken,
          };
        },
      }),
      // when condition is false, use batching (default behavior)
      false: httpBatchLink({
        url: getUrl(),
        async headers() {
          const firebaseToken = await auth.currentUser?.getIdTokenResult();
          const authToken = `Bearer ${firebaseToken?.token}`;
          if (!firebaseToken) {
            return {};
          }
          return {
            Authorization: authToken,
          };
        },
      }),
    }),
  ],
});
