import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { getQueryParam } from 'src/utils/queryParams';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import { useRedirect } from '../utils/useRedirect';

export const Redirector: FC = () => {
  const redirect = useRedirect();
  const firstLandingParam = getQueryParam('first_landing');

  const isInspireUiEnabled = useFeatureFlag('INSPIRE_UI_ENABLED');
  const isInsightsUiEnabled = useFeatureFlag('ASSETS_UI');
  let baseHomePath;
  if (isInspireUiEnabled) {
    baseHomePath = '/library/discover';
  } else if (isInsightsUiEnabled) {
    baseHomePath = '/insights';
  } else {
    baseHomePath = '/briefs';
  }
  const homePath =
    firstLandingParam === 'true'
      ? `${baseHomePath}?first_landing=true`
      : baseHomePath;

  return (
    <Navigate to={redirect.shouldRedirect ? redirect.redirectPath : homePath} />
  );
};
