import { useCallback } from 'react';
import {
  ActiveMetric,
  ActiveMetricAction,
  ActiveMetricCustom,
  ActiveMetricMulti,
  ActiveMetricNumerical,
  ActiveMetricText,
  ActiveMetricWizard,
  ArrayElement,
  DisplayableMetric,
  displayableMetrics,
  INSIGHTS_FACEBOOK_ACTION_TYPES,
  InsightsFacebookAction,
  InsightsGroup,
  Metric,
  MultiMetric,
  multiMetrics,
  NumericalMetric,
  numericalMetrics,
  SelectableTimePeriod,
  SortableMetric,
  sortableMetrics,
  sortAlphabetically,
  TextMetric,
  textMetrics,
  WizardMetric,
  wizardMetrics,
} from '@magicbrief/common';
import { useTypesafeSearchParams } from 'src/utils/useTypesafeSearchParams';

function activeMetricToString(item: ActiveMetric): MetricString | null {
  const metricString: MetricString = `${item.metric}|${item.operation}`;
  if (item.metricType === 'text') {
    return `${metricString}${item.value1 != null ? `|${item.value1}` : ''}`;
  } else if (
    item.metricType === 'numerical' ||
    item.metricType === 'action' ||
    item.metricType === 'wizard'
  ) {
    return `${metricString}${item.value1 != null ? `|${item.value1}` : ''}${
      item.value2 != null ? `|${item.value2}` : ''
    }`;
  } else if (item.metricType === 'multi') {
    return `${metricString}${
      item.value1 != null ? `|${item.value1.join('_')}` : ''
    }`;
  } else if (item.metricType === 'custom') {
    return `${metricString}${item.value1 != null ? `|${item.value1}` : ''}${
      item.value2 != null ? `|${item.value2}` : ''
    }`;
  } else {
    return null;
  }
}

function activeMetricFromString(item: MetricString): ActiveMetric | null {
  const [metric, operation, value1, value2] = item.split('|');

  if (multiMetrics.includes(metric as MultiMetric)) {
    const parsedValue1 = value1 ? value1.split('_') : [];

    return {
      metricType: 'multi',
      metric,
      operation,
      value1: parsedValue1,
    } as ActiveMetricMulti;
  } else if (numericalMetrics.includes(metric as NumericalMetric)) {
    const parsedValue1 = value1 ? parseFloat(value1) : undefined;
    const parsedValue2 = value2 ? parseFloat(value2) : undefined;

    return {
      metricType: 'numerical',
      metric,
      operation,
      value1: parsedValue1,
      value2: parsedValue2,
    } as ActiveMetricNumerical;
  } else if (wizardMetrics.includes(metric as WizardMetric)) {
    const parsedValue1 = value1 ? parseFloat(value1) : undefined;
    const parsedValue2 = value2 ? parseFloat(value2) : undefined;

    return {
      metricType: 'wizard',
      metric,
      operation,
      value1: parsedValue1,
      value2: parsedValue2,
    } as ActiveMetricWizard;
  } else if (
    INSIGHTS_FACEBOOK_ACTION_TYPES.includes(metric as InsightsFacebookAction)
  ) {
    const parsedValue1 = value1 ? parseFloat(value1) : undefined;
    const parsedValue2 = value2 ? parseFloat(value2) : undefined;

    return {
      metricType: 'action',
      metric,
      operation,
      value1: parsedValue1,
      value2: parsedValue2,
    } as ActiveMetricAction;
  } else if (textMetrics.includes(metric as TextMetric)) {
    return {
      metricType: 'text',
      metric,
      operation,
      value1,
    } as ActiveMetricText;
  } else if (metric.startsWith('custom:')) {
    const parsedValue1 = value1 ? parseFloat(value1) : undefined;
    const parsedValue2 = value2 ? parseFloat(value2) : undefined;

    return {
      metricType: 'custom',
      metric,
      operation,
      value1: parsedValue1,
      value2: parsedValue2,
    } as ActiveMetricCustom;
  } else {
    return null;
  }
}

const sortFilter = (sort: (SortableMetric | string)[] = []) => [
  ...new Set(
    sort.filter(
      (key) =>
        sortableMetrics.includes(key as SortableMetric) ||
        key.startsWith('custom:')
    )
  ),
];

const metricFilter = (metric: ActiveMetric[] = []) => [
  ...new Set(
    metric
      .map(activeMetricToString)
      .filter((x): x is Exclude<typeof x, null> => !!x)
      .sort(sortAlphabetically)
  ),
];

const displayFilter = (display: (DisplayableMetric | string)[] = []) => [
  ...new Set(
    display
      .filter(
        (key) =>
          displayableMetrics.includes(key as DisplayableMetric) ||
          key.startsWith('custom:')
      )
      .sort(sortAlphabetically)
  ),
];

export type MetricString =
  /** @example text:      'adName|isSet' */
  | `${string}|${string}`
  /** @example text:      'adName|is|checkout%20new%20feature' */
  /** @example numerical: 'spend|equals|2.0' */
  /** @example multi:     'creativeType|in|image_video_carousel' */
  | `${string}|${string}|${string}`
  /** @example numerical: 'spend|between|2.0|3.0' */
  | `${string}|${string}|${string}|${string}`;

type InsightsSearchParams = {
  sort: (SortableMetric | string)[];
  metric: MetricString[];
  timePeriod: SelectableTimePeriod;
  display: (Metric | string)[];
  group: InsightsGroup;
  attributionWindow: 'default' | 'custom';
  view: 'table' | 'grid';
};

type InsightsSearchParamsKey = keyof InsightsSearchParams;

export function useInsightsSearchParams() {
  const search = useTypesafeSearchParams<InsightsSearchParams>({
    sort: ['spend', 'clicks', 'impressions'],
    metric: [],
    timePeriod: 'last_14d',
    display: [
      'spend',
      'roas',
      'link_click',
      'cpm',
      'cpc',
      'wizardHookScore',
      'wizardHoldScore',
      'wizardClickScore',
    ],
    group: null,
    attributionWindow: 'default',
    view: 'table',
  });

  const getRawValues = () => ({
    sort: search.get('sort'),
    metric: search.get('metric'),
    timePeriod: search.get('timePeriod'),
    display: search.get('display'),
    group: search.get('group'),
    attributionWindow: search.get('attributionWindow'),
  });

  const getParsedValues = () => ({
    sort: search.get('sort'),
    metric:
      search
        .get('metric')
        ?.map(activeMetricFromString)
        .filter((x): x is Exclude<typeof x, null> => !!x) ?? [],
    timePeriod: search.get('timePeriod') ?? 'last_14d',
    display: search.get('display'),
    group: search.get('group'),
    attributionWindow: search.get('attributionWindow'),
  });

  const setSortValue = (values: InsightsSearchParams['sort']) =>
    search.set('sort', sortFilter(values));

  const setMetricValue = (values: ActiveMetric[]) =>
    search.set('metric', metricFilter(values));

  const setTimePeriodValue = (value: InsightsSearchParams['timePeriod']) =>
    search.set('timePeriod', value);

  const setDisplayValue = (values: InsightsSearchParams['display']) =>
    search.set('display', sortFilter(values));

  const setManyDisplayValues = (values: InsightsSearchParams['display']) =>
    search.setMany({ display: displayFilter(values) });

  const toggleSortValue = (value: ArrayElement<InsightsSearchParams['sort']>) =>
    search.toggle('sort', value);

  const toggleMetricValue = (value: ActiveMetric) => {
    const metric = activeMetricToString(value);
    if (metric) {
      search.toggle('metric', metric);
    }
  };

  const toggleDisplayValue = (
    value: ArrayElement<InsightsSearchParams['display']>
  ) => search.toggle('display', value);

  const deleteSortValue = (value: ArrayElement<InsightsSearchParams['sort']>) =>
    search.deleteByKeyValuePair('sort', value);

  const deleteMetricValue = (value: ActiveMetric) => {
    const metric = activeMetricToString(value);
    if (metric) {
      search.deleteByKeyValuePair('metric', metric);
    }
  };

  const deleteDisplayValue = (
    value: ArrayElement<InsightsSearchParams['display']>
  ) => search.deleteByKeyValuePair('display', value);

  const setManyValues = useCallback(
    (values: {
      sort: (SortableMetric | string)[];
      metric: ActiveMetric[];
      timePeriod: SelectableTimePeriod;
      display?: (DisplayableMetric | string)[];
      group?: InsightsGroup | null;
    }) => {
      const sort = sortFilter(values.sort);
      const metric = metricFilter(values.metric);
      const display = displayFilter(values.display);

      return search.setMany({
        sort,
        metric,
        timePeriod: values.timePeriod,
        display,
        group: values.group,
      });
    },
    [search]
  );

  const deleteValues = (key: InsightsSearchParamsKey) =>
    search.deleteByKey(key);

  return {
    getRawValues,
    getParsedValues,
    setSortValue,
    setMetricValue,
    setTimePeriodValue,
    setDisplayValue,
    setManyDisplayValues,
    toggleSortValue,
    toggleMetricValue,
    toggleDisplayValue,
    deleteSortValue,
    deleteMetricValue,
    deleteDisplayValue,
    setManyValues,
    deleteValues,
    params: search,
  };
}
