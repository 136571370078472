import React from 'react';
import {
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  MenuTrigger,
  MenuTriggerProps,
  PopoverProps,
} from 'react-aria-components';
import classNames from 'classnames';
import Check from 'src/assets/svgicons/line/check.svg';
import { Icon } from '../Icon';
import { AriaPopover } from '../AriaPopover/AriaPopover';
import { usePopoverState } from '../AriaPopover/usePopoverState';

type Props = Omit<MenuTriggerProps, 'children'> & {
  children: [React.ReactElement, ...React.ReactNode[]];
  /** blocks menu from opening */
  isDisabled?: boolean;
  placement?: PopoverProps['placement'];
  className?: string;
};

const AriaMenuItem: React.FC<
  Omit<MenuItemProps, 'className'> & {
    className?: string;
    contentClassName?: string;
    checkboxClassName?: string;
    icon?: React.ReactNode;
    danger?: boolean;
    showCheckbox?: boolean;
  }
> = ({
  className,
  contentClassName,
  checkboxClassName,
  children,
  icon,
  danger,
  showCheckbox = true,
  ...props
}) => (
  <MenuItem
    {...props}
    className={classNames(
      'group flex items-center px-4 py-2 text-sm gap-3 h-10 outline-none cursor-default box-border rounded overflow-hidden font-semibold',
      danger
        ? 'text-danger focus:bg-red-50'
        : 'focus:bg-purple-100 text-primary',
      className
    )}
  >
    {(params) => (
      <>
        {showCheckbox && (
          <span
            className={classNames(
              'w-5 h-5 [&>*]:h-full [&>*]:w-full shrink-0',
              checkboxClassName
            )}
          >
            {icon ?? (
              <Icon className="group-selected:block hidden">
                <Check />
              </Icon>
            )}
          </span>
        )}

        <span className={classNames(contentClassName)}>
          {typeof children === 'function' ? children(params) : children}
        </span>
      </>
    )}
  </MenuItem>
);

type AriaMenuListProps<T> = MenuProps<T>;

function AriaMenuList<T extends object>({
  className,
  ...rest
}: AriaMenuListProps<T>) {
  return (
    <Menu {...rest} className={classNames('focus:outline-none', className)} />
  );
}

type AriaMenuCompositeComponent = React.FC<Props> & {
  Item: typeof AriaMenuItem;
  List: typeof AriaMenuList;
};

export const AriaMenu: AriaMenuCompositeComponent = ({
  children,
  className,
  onOpenChange,
  placement,
  isDisabled,
  ...rest
}) => {
  const [popoverState, setPopoverState] = usePopoverState();

  return (
    <MenuTrigger
      {...rest}
      onOpenChange={(isOpen) => {
        if (!isDisabled) {
          setPopoverState(isOpen ? 'animate' : 'initial');
        }
        onOpenChange?.(isOpen);
      }}
    >
      {children[0]}
      <AriaPopover
        className={classNames(
          className,
          'w-56 rounded-md bg-white shadow-md ring-1 ring-purple-300 focus:outline-none outline-none overflow-auto p-1'
        )}
        state={popoverState}
        onStateChange={setPopoverState}
        placement={placement}
      >
        {...children.slice(1)}
      </AriaPopover>
    </MenuTrigger>
  );
};

AriaMenu.List = AriaMenuList;
AriaMenu.Item = AriaMenuItem;
