import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { inferProcedureInput, inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TRPCClientErrorLike } from '@trpc/client';
import { UseTRPCInfiniteQueryOptions } from '@trpc/react-query/shared';
import { OmitAcrossUnion } from '@magicbrief/common';
import { useSearchParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import { useI18nContext } from 'src/i18n/i18n-react';
import { ShallowAdResponse } from 'src/types/ad';
import { getFeatureFlagValue } from '../utils/getFeatureFlagValue';
import type { AdQuery } from '@magicbrief/server/src/api/models/shapes/trpcshapes';

type AdsPage = inferProcedureOutput<AppRouter['ads']['getAds']>;

export const PageTakeLimit = 20;

function useIsAdsEmpty(ads: UseInfiniteQueryResult<AdsPage>) {
  return (
    ads.isSuccess &&
    !ads.isFetching &&
    (ads.data.pages.length === 0 || ads.data.pages[0].ads.length === 0)
  );
}

function getNextPageParam(lastPage: AdsPage) {
  if (lastPage.ads.length < PageTakeLimit) {
    return undefined;
  }
  return lastPage.ads[lastPage.ads.length - 1].id;
}

function getNextPageParamV2(lastPage: AdsPage, allPages: AdsPage[]) {
  if (lastPage.ads.length < PageTakeLimit) {
    return undefined;
  }
  return allPages.length;
}

function handleError(
  userMessage: string,
  error: unknown,
  transaction: string,
  extra: unknown
) {
  Sentry.captureException(error, (scope) => {
    scope.setTransactionName(transaction);
    scope.setExtras({ extra });
    return scope;
  });
  toast.error(userMessage, { className: 'toast-danger' });
}

export function useTRPCAdsQuery(
  query: OmitAcrossUnion<AdQuery, 'count'>,
  options?: Omit<
    UseTRPCInfiniteQueryOptions<
      'ads.getAds',
      inferProcedureInput<AppRouter['ads']['getAds']>,
      inferProcedureOutput<AppRouter['ads']['getAds']>,
      TRPCClientErrorLike<AppRouter['ads']['getAds']>
    >,
    'getNextPageParam'
  >
) {
  const { LL } = useI18nContext();
  const [search] = useSearchParams();
  const firstLanding = search.get('first_landing') === 'true';

  const ads = trpc.ads.getAds.useInfiniteQuery(
    {
      ...query,
      count: PageTakeLimit,
      ruleContexts: firstLanding ? ['first_landing'] : undefined,
    },
    {
      ...options,
      getNextPageParam:
        query.queryType === 'discover' &&
        getFeatureFlagValue('SEARCH_VERSION') === 'v2'
          ? getNextPageParamV2
          : getNextPageParam,
      onError: (error, ...rest) => {
        handleError(
          LL.errors.genericWithDetail({
            detail: error instanceof Error ? error.message : 'Unknown error',
          }),
          error,
          'Fetching ads',
          query
        );
        options?.onError?.(error, ...rest);
      },
    }
  );
  const isEmpty = useIsAdsEmpty(ads);

  const getAdAtFlatIndex = useCallback(
    (
      index: number
    ): { ad: ShallowAdResponse; queryID: string | null } | null => {
      const pageIndex = Math.floor(index / PageTakeLimit);
      const withinPageIndex = index - pageIndex * PageTakeLimit;
      if (
        ads.data &&
        index >= 0 &&
        pageIndex < ads.data.pages.length &&
        withinPageIndex < ads.data.pages[pageIndex]?.ads.length
      ) {
        const ad = ads.data.pages[pageIndex].ads[withinPageIndex];
        return { ad, queryID: ads.data.pages[pageIndex].queryID } ?? null;
      }
      return null;
    },
    [ads.data]
  );

  return {
    ads,
    isEmpty,
    getAdAtFlatIndex,
  };
}
