import { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import Spinner from 'src/components/Loaders/Spinner';
import BaseButton, {
  BaseButtonLink,
  AriaBaseButton,
  AriaBaseButtonProps,
} from './BaseButton';

type CommonProps = {
  /**
   * Size of the button. Defaults to "normal".
   */
  size?: ButtonSize;
  /**
   * How the button should be filled and styled.
   */
  variant?: ButtonVariants;
  /**
   * The colour theme appearance the button should take on.
   */
  colour?: ButtonColours;
  /**
   * Optional icon for button.
   */
  icon?: React.ReactNode | null;
  /**
   * Sets a loading state on the button, disabling it and giving it a spinner icon.
   */
  loading?: boolean;
  /**
   * Disables the box shadow on the button.
   */
  flat?: boolean;
  /**
   * Makes the button fully rounded.
   */
  round?: boolean;
};

type Props = CommonProps & {
  /**
   * The raw HTML type attribute for a button (i.e. "submit", "button", etc).
   */
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
} & Omit<React.ComponentPropsWithoutRef<typeof BaseButton>, 'type' | 'color'>;

export type AriaButtonProps = CommonProps & {
  /**
   * The raw HTML type attribute for a button (i.e. "submit", "button", etc).
   */
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
} & Omit<AriaBaseButtonProps, 'type' | 'color'>;

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'gradient'
  | 'text'
  | 'admin'
  | 'custom';
type ButtonColours = 'primary' | 'danger';
type ButtonSize = 'normal' | 'large' | 'small' | 'smallest';

const baseStyle =
  'font-bold py-2 px-3 transition-colors greyscale-0 focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-500';

const normalStyle = 'h-10 text-sm py-2 px-3';
const largeStyle = 'h-14 text-base py-2 px-5';
const smallerStyle = 'h-8 text-sm py-2 px-3';
const smallestStyle = 'h-6 text-xs py-2 px-3';

const primaryStyle =
  'bg-primary border border-primary border-solid text-white fill-white hover:bg-purple-700 hover:border-purple-700';
const primaryGradientStyle =
  'bg-primary text-white bg-gradient-to-b from-primary-gradient-start to-primary-gradient-stop';

const secondaryStyle =
  'text-primary border border-solid border-purple-300 bg-purple-50 hover:bg-purple-100';

const whiteStyle =
  'text-primary border border-purple-200 border-solid bg-white hover:bg-purple-50';
const whiteDangerStyle =
  'text-danger border border-solid border-purple-300 bg-white hover:bg-purple-100';

const dangerStyle =
  'text-white bg-danger border border-solid border-danger hover:bg-red-700 hover:border-red-700';
const dangerSecondaryStyle =
  'text-danger bg-red-50 border border-solid border-red-300 hover:bg-red-100';
const dangerGradientStyle =
  'text-white border bg-gradient-to-b from-danger-gradient-start to-danger-gradient-stop';

const adminStyle =
  'bg-purple-500 border border-purple-500 border-solid text-white fill-white hover:bg-purple-600 hover:border-purple-600';

const textStyle = 'text-primary bg-transparent hover:bg-primary/[0.15]';
const dangerTextStyle = 'text-danger bg-transparent hover:bg-danger/[0.15]';

const styles: Record<
  Exclude<ButtonVariants, 'custom'>,
  Record<ButtonColours, string>
> = {
  primary: {
    primary: primaryStyle,
    danger: dangerStyle,
  },
  secondary: {
    primary: secondaryStyle,
    danger: dangerSecondaryStyle,
  },
  white: {
    primary: whiteStyle,
    danger: whiteDangerStyle,
  },
  gradient: {
    primary: primaryGradientStyle,
    danger: dangerGradientStyle,
  },
  admin: {
    primary: adminStyle,
    danger: dangerGradientStyle,
  },
  text: {
    primary: textStyle,
    danger: dangerTextStyle,
  },
};

const sizeStyles: Record<ButtonSize, string> = {
  normal: normalStyle,
  small: smallerStyle,
  smallest: smallestStyle,
  large: largeStyle,
};

const iconSizeStyles: Record<ButtonSize, string> = {
  normal: 'h-5.5 w-5.5',
  large: 'h-6 w-6',
  small: 'h-5.5 w-5.5',
  smallest: 'h-5 w-5',
};

function calculateStyle(
  variant: ButtonVariants,
  size: ButtonSize,
  colour: ButtonColours,
  flat: boolean,
  round: boolean,
  hasIcon: boolean,
  hasChildren: boolean,
  disabled: boolean,
  loading: boolean,
  className?: string
) {
  if (variant === 'custom') {
    return classNames(
      baseStyle,
      sizeStyles[size],
      hasIcon && !hasChildren ? 'w-10' : '', // Square button when icon-only
      className
    );
  }

  return classNames(
    baseStyle,
    round ? 'rounded-full' : 'rounded-lg',
    sizeStyles[size],
    styles[variant][colour],
    flat || variant === 'text' ? 'shadow-none' : 'shadow-sm',
    hasIcon && !hasChildren ? 'w-10' : '', // Square button when icon-only
    disabled
      ? 'opacity-40 cursor-not-allowed'
      : `${loading ? 'cursor-wait opacity-40' : 'cursor-pointer'}`,
    className
  );
}

/**
 * @deprecated Use AriaButton
 * Primary UI component for user interaction
 */
export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primary',
      colour = 'primary',
      icon,
      disabled = false,
      loading = false,
      htmlType = 'button',
      children,
      className,
      size = 'normal',
      flat = false,
      round = false,
      ...props
    },
    ref
  ) => {
    return (
      <BaseButton
        ref={ref}
        icon={
          loading ? (
            <div className={iconSizeStyles[size]}>
              <Spinner />
            </div>
          ) : icon ? (
            <ButtonIcon size={size}>{icon}</ButtonIcon>
          ) : null
        }
        disabled={disabled || loading}
        type={htmlType}
        className={calculateStyle(
          variant,
          size,
          colour,
          flat,
          round,
          !!icon,
          !!children,
          disabled,
          loading,
          className
        )}
        {...props}
      >
        {children}
      </BaseButton>
    );
  }
);

Button.displayName = 'Button';

export const AriaButton = forwardRef<HTMLButtonElement, AriaButtonProps>(
  (
    {
      variant = 'primary',
      colour = 'primary',
      icon,
      isDisabled = false,
      loading = false,
      htmlType = 'button',
      children,
      className,
      size = 'normal',
      flat = false,
      round = false,
      ...props
    },
    ref
  ) => {
    return (
      <AriaBaseButton
        ref={ref}
        icon={
          loading ? (
            <div className={iconSizeStyles[size]}>
              <Spinner />
            </div>
          ) : icon ? (
            <ButtonIcon size={size}>{icon}</ButtonIcon>
          ) : null
        }
        isDisabled={isDisabled || loading}
        type={htmlType}
        className={calculateStyle(
          variant,
          size,
          colour,
          flat,
          round,
          !!icon,
          !!children,
          isDisabled,
          loading,
          className
        )}
        {...props}
      >
        {children}
      </AriaBaseButton>
    );
  }
);

AriaButton.displayName = 'Button';

type ButtonLinkProps = CommonProps &
  React.PropsWithChildren<{ className?: string }> &
  (
    | {
        type: 'internal';
        // A relative React Router path. Providing this will
        // cause the component to render as a Link.
        to: string;
      }
    | {
        type: 'external';
        // A URL to be navigated to in an external tab. When provided
        // this will cause the component to render as an anchor tag.
        href: string;
      }
  ) &
  React.ComponentPropsWithoutRef<'a'>;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      variant = 'primary',
      colour = 'primary',
      icon,
      loading = false,
      children,
      className,
      size = 'normal',
      flat = false,
      round = false,
      ...props
    },
    ref
  ) => {
    return (
      <BaseButtonLink
        {...props}
        ref={ref}
        icon={
          loading ? (
            <div className={iconSizeStyles[size]}>
              <Spinner />
            </div>
          ) : icon ? (
            <ButtonIcon size={size}>{icon}</ButtonIcon>
          ) : null
        }
        className={classNames(
          'flex flex-row items-center justify-center',
          calculateStyle(
            variant,
            size,
            colour,
            flat,
            round,
            !!icon,
            !!children,
            false,
            loading,
            className
          )
        )}
      >
        {children}
      </BaseButtonLink>
    );
  }
);

ButtonLink.displayName = 'ButtonLink';

type ButtonIconProps = {
  /**
   * Size of the button. Defaults to "normal".
   */
  size: ButtonSize;
};

export const ButtonIcon: React.FunctionComponent<
  PropsWithChildren<ButtonIconProps>
> = ({ children, size }) => {
  return (
    <span
      className={classNames(
        'flex items-center justify-center fill-inherit',
        iconSizeStyles[size]
      )}
    >
      {children}
    </span>
  );
};
