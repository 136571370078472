import {
  DeploymentEnvironment,
  getFeatureFlagValue as commonGetFeatureFlagValue,
} from '@magicbrief/common';
import { useUserAndOrganisation } from './useUserAndOrganisation';
import type { Feature, FeatureFlags } from '@magicbrief/common';

export default function useFeatureFlag<T extends Feature>(
  key: T,
  override?: FeatureFlags[T]
): FeatureFlags[T] | null {
  const user = useUserAndOrganisation();
  const featureFlagConfig = user.data?.featureFlags;

  if (user.isLoading) {
    return null;
  }

  return commonGetFeatureFlagValue(
    key,
    import.meta.env.VITE_DEPLOYMENT as DeploymentEnvironment,
    override || (featureFlagConfig?.[key] ?? undefined)
  );
}
