interface Storage<Key> {
  getItem: (key: Key) => string | null;
  setItem: (key: Key, newValue: string) => void;
  getOrSetItem: (key: Key, value?: string) => string;
}

const localStorageKeys = {
  /** list of open directories / collections for a user */
  openFolders: 'openFolders',
  /** map of the last insights account viewed per user org */
  insightsLastViewedAccount: 'insightsLastViewedAccount',
  /**  when a user has been sent an Organisation invite link, we store the invite code */
  inviteCodes: 'inviteCodes',
  /** @todo if we don't know what this is in a few months, please delete */
  /** the referral ID a user is sent */
  referralID: 'referralID',
};

type LocalStorageKey = keyof typeof localStorageKeys;

class LocalStorage implements Storage<LocalStorageKey> {
  getItem(key: LocalStorageKey): string | null {
    return window.localStorage.getItem(key);
  }

  setItem(key: LocalStorageKey, newValue: string): void {
    const oldValue = this.getItem(key);
    window.localStorage.setItem(key, newValue);
    window.dispatchEvent(
      new StorageEvent('storage', { key, oldValue, newValue })
    );
  }

  getOrSetItem(key: LocalStorageKey, value = ''): string {
    const current = this.getItem(key);

    if (current != null) {
      return current;
    }

    this.setItem(key, value);

    return value;
  }

  removeItem(key: LocalStorageKey): void {
    const oldValue = this.getItem(key);
    window.localStorage.removeItem(key);
    window.dispatchEvent(
      new StorageEvent('storage', { key, oldValue, newValue: undefined })
    );
  }
}

const sessionStorageKeys = {
  slackOAuthRedirectPath: 'slackOAuthRedirectPath',
};

type SessionStorageKey = keyof typeof sessionStorageKeys;

class SessionStorage implements Storage<SessionStorageKey> {
  getItem(key: SessionStorageKey): string | null {
    return window.sessionStorage.getItem(key);
  }

  setItem(key: SessionStorageKey, newValue: string): void {
    window.sessionStorage.setItem(key, newValue);
  }

  getOrSetItem(key: SessionStorageKey, value = ''): string {
    const current = this.getItem(key);

    if (current != null) {
      return current;
    }

    this.setItem(key, value);

    return value;
  }

  removeItem(key: SessionStorageKey): void {
    window.sessionStorage.removeItem(key);
  }
}

export const localStorage = new LocalStorage();
export const sessionStorage = new SessionStorage();
