import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import BaseModal, { BaseModalProps } from './BaseModal';
import ModalActions from './components/ModalActions';
import ModalBody from './components/ModalBody';
import ModalHeader from './components/ModalHeader';

type Props = PropsWithChildren<{
  /**
   * Title of the modal.
   */
  title: string;
  /**
   * Display a close button in the top right corner. Defaults to true.
   */
  showCloseButton?: boolean;
  /**
   * Footer to display buttons or whatever else necessary. Typically, this will be buttons.
   */
  footer?: ReactNode | null;
}> &
  Omit<BaseModalProps, 'children' | 'panelClassName'>;

/** @deprecated use {@link src/components/AriaModal/AriaModal} */
const SimpleModal: FC<Props> = ({
  title,
  children,
  onClose,
  show,
  showCloseButton = true,
  footer,
  className,
  ...rest
}): JSX.Element => {
  return (
    <BaseModal
      show={show}
      onClose={onClose}
      panelClassName={classNames(
        'pb-8 max-w-md w-full bg-white rounded-xl',
        className
      )}
      {...rest}
    >
      <div>
        <ModalHeader
          className="min-w-0 px-8 pt-6"
          onClose={showCloseButton ? onClose : null}
        >
          {title}
        </ModalHeader>
        <div>
          {children && <ModalBody>{children}</ModalBody>}
          {footer && <ModalActions>{footer}</ModalActions>}
        </div>
      </div>
    </BaseModal>
  );
};

export default SimpleModal;
