import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  BrowserTracing,
  init,
  reactRouterV6Instrumentation,
  replayIntegration,
} from '@sentry/react';
import { isProdDeploy } from '@magicbrief/common';
import { sentryConfigs } from 'src/configs';
import appConfig from '../configs/app';

export function initSentry() {
  if (appConfig.env === 'development') {
    console.debug('Not initialising Sentry in development mode');
    return;
  }

  init({
    dsn: sentryConfigs.dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),

      replayIntegration(),
    ],
    tracesSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    replaysSessionSampleRate: isProdDeploy(import.meta.env) ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
    release: appConfig.release,
    environment: appConfig.deployment,
  });
}
