import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import aa from 'search-insights';
import { getLibrarySearchFiltersFromURLParams } from 'src/utils/getLibrarySearchFiltersFromURLParams';

const INDEX_NAME = `${(
  import.meta.env.VITE_DEPLOYMENT as string
).toLowerCase()}_ADS`;

export function initAlgoliaInsights() {
  aa('init', {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID,
    apiKey: import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY,
  });
}

/**
 * Call this hook once in the root of the app to initialise Algolia Insights.
 */
export function useSetAlgoliaInsightsUserToken(userUuid: string | null) {
  useEffect(() => {
    if (userUuid) {
      aa('setUserToken', userUuid);
    }
  }, [userUuid]);
}

/**
 * Send a click event on an ad in the library to Algolia Insights.
 */
export function captureAlgoliaLibraryItemClick(
  uuid: string,
  itemIndex: number,
  queryID: string | null
) {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const computedQueryID = queryID ?? searchParams.get('queryID');

    if (isSearchActive(searchParams) && computedQueryID) {
      aa('clickedObjectIDsAfterSearch', {
        eventName: 'Library Item Clicked',
        objectIDs: [uuid],
        index: INDEX_NAME,
        positions: [itemIndex],
        queryID: computedQueryID,
      });
    } else {
      aa('clickedObjectIDs', {
        eventName: 'Library Item Clicked',
        objectIDs: [uuid],
        index: INDEX_NAME,
      });
    }
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('captureAlgoliaLibraryItemClick');
      return scope;
    });
  }
}

/**
 * Send a library ad viewed event to Algolia Insights.
 */
export function captureAlgoliaLibraryItemViewed(uuid: string) {
  try {
    aa('viewedObjectIDs', {
      eventName: 'Library Item Viewed',
      objectIDs: [uuid],
      index: INDEX_NAME,
    });
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('captureAlgoliaLibraryItemViewed');
      return scope;
    });
  }
}

/**
 * Send a library ad saved with filters event to Algolia Insights.
 * This appears to be distinct from standard conversion/save events.
 * Presumably we need to send both the convertedObjectIDs event and this one.
 */
function captureAlgoliaFiltersActiveWhenItemSaved() {
  try {
    const filters = getLibrarySearchFiltersFromURLParams();

    // Algolia insights accepts a max of 10 filters
    // The most relevant should be listed first to maximise
    // our ability to make use of the data.
    const topTenFilters: Array<FilterTuple> = [
      ...filters.brands.map((x): FilterTuple => ['brand.uuid', x]),
      ...filters.industries.map((x): FilterTuple => ['categories', x]),
      ...filters.supportedPlatforms.map(
        (x): FilterTuple => ['supportedPlatforms', x]
      ),
      ...filters.source.map((x): FilterTuple => ['source', x]),
      ...filters.format.map((x): FilterTuple => ['mediaType', x]),
      ...filters.scores.map((x): FilterTuple => ['adScoreTier', x]),
      ...filters.themes.map((x): FilterTuple => ['themes', x]),
      ...filters.runTime.map((x): FilterTuple => ['runTime', x]),
    ].slice(undefined, 10);

    if (topTenFilters.length === 0) {
      return;
    }

    aa('convertedFilters', {
      eventName: 'Library Item Saved With Filters',
      index: INDEX_NAME,
      filters: topTenFilters.map(
        ([filter, value]) => `${filter}:${encodeURIComponent(value)}`
      ),
    });
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('captureAlgoliaFiltersActiveWhenItemSaved');
      return scope;
    });
  }
}

/**
 * Send a library ad saved with filters event to Algolia Insights.
 */
export function captureAlgoliaLibraryItemSaved(
  uuid: string,
  queryID: string | null
) {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const computedQueryID = queryID ?? searchParams.get('queryID');

    if (isSearchActive(searchParams) && computedQueryID) {
      aa('convertedObjectIDsAfterSearch', {
        eventName: 'Library Item Saved',
        objectIDs: [uuid],
        index: INDEX_NAME,
        queryID: computedQueryID,
      });
    } else {
      aa('convertedObjectIDs', {
        eventName: 'Library Item Saved',
        objectIDs: [uuid],
        index: INDEX_NAME,
      });
    }
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('captureAlgoliaLibraryItemSaved');
      return scope;
    });
  }

  captureAlgoliaFiltersActiveWhenItemSaved();
}

type FilterTuple =
  | [
      (
        | 'adScoreTier'
        | 'brand.uuid'
        | 'categories'
        | 'mediaType'
        | 'source'
        | 'supportedPlatforms'
        | 'themes'
        | 'runTime'
        | 'languages'
      ),
      string | number,
    ]
  | readonly [
      (
        | 'adScoreTier'
        | 'brand.uuid'
        | 'categories'
        | 'mediaType'
        | 'source'
        | 'supportedPlatforms'
        | 'themes'
        | 'runTime'
        | 'languages'
      ),
      string | number,
    ];

/**
 * Send a faceted filter clicked event to Algolia. It is not documented
 * in Algolia's docs as to whether this should include filter *off* clicks.
 * For now, assume that only filters being turned *on* should call this.
 */
export function captureAlgoliaFilterClicked(filters: Array<FilterTuple>) {
  try {
    aa('clickedFilters', {
      eventName: 'Library Filter Clicked',
      filters: filters.map(
        ([filter, value]) => `${filter}:${encodeURIComponent(value)}`
      ),
      index: INDEX_NAME,
    });
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('captureAlgoliaFilterClicked');
      return scope;
    });
  }
}

function isSearchActive(urlSearchParams: URLSearchParams) {
  const active =
    isTextSearchActive(urlSearchParams) || isFilterActive(urlSearchParams);

  return active;
}

function isTextSearchActive(urlSearchParams: URLSearchParams) {
  const active = urlSearchParams.has('search');

  return active;
}

function isFilterActive(urlSearchParams: URLSearchParams) {
  const active =
    urlSearchParams.has('brands') ||
    urlSearchParams.has('source') ||
    urlSearchParams.has('supportedPlatforms') ||
    urlSearchParams.has('industries') ||
    urlSearchParams.has('format') ||
    urlSearchParams.has('themes') ||
    urlSearchParams.has('scores');

  return active;
}
