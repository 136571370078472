import { FC, useState } from 'react';
import { ArrayElement } from '@magicbrief/common';
import classNames from 'classnames';
import { Button, Text } from 'react-aria-components';
import { useNavigate, useParams } from 'react-router-dom';
import FolderReport from 'src/assets/svgicons/custom/FolderReport.svg';
import Target05 from 'src/assets/svgicons/duocolor/target-05.svg';
import Trash01 from 'src/assets/svgicons/duocolor/trash-01.svg';
import { DialogPopover } from 'src/components/DialogPopover/DialogPopover';
import { Icon } from 'src/components/Icon';
import Sidebar from 'src/components/Sidebar/Sidebar';
import { SquareLoaders } from 'src/components/SquareLoaders';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { SlackGradient } from '../../../assets/svgicons/brands';
import Copy01 from '../../../assets/svgicons/duocolor/copy-01.svg';
import DotsHorizontal from '../../../assets/svgicons/duocolor/dots-horizontal.svg';
import Edit01 from '../../../assets/svgicons/duocolor/edit-01.svg';
import Settings01 from '../../../assets/svgicons/duocolor/settings-01.svg';
import BarChartSquare02 from '../../../assets/svgicons/line/bar-chart-square-02.svg';
import ChevronSelectorVertical from '../../../assets/svgicons/line/chevron-selector-vertical.svg';
import { AriaMenu } from '../../../components/AriaMenu/AriaMenu';
import { AriaButton } from '../../../components/Button/Button';
import { FacebookConnect } from '../../../components/FacebookConnect/FacebookConnect';
import { GetManyInsightsReportsResponse } from '../../../types/insights';
import useFeatureFlag from '../../../utils/useFeatureFlag';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import DeleteReportModal from './DeleteReportModal';
import DuplicateReportModal from './DuplicateReportModal';
import RenameReportModal from './RenameReportModal';

const InsightsSidebar: FC = () => {
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const { accountUuid, reportUuid } = useParams();

  const user = useUserAndOrganisation();

  const slackEnabled = useFeatureFlag('SLACK_CONNECT_ENABLED');
  const copilotEnabled = useFeatureFlag('INSIGHTS_COPILOT_ENABLED');

  const integrations = trpc.integrations.getIntegrations.useQuery(undefined, {
    enabled: !!user.data,
  });
  const facebookIntegrations =
    integrations.data?.filter(
      (integration) => integration.integrationType === 'facebook'
    ) || [];

  const facebookAdAccounts = trpc.insights.getManyFacebookAdAccounts.useQuery(
    undefined,
    { enabled: !!integrations.data }
  );

  const currentAdAccount = facebookAdAccounts.data?.find(
    (adAccount) => adAccount.uuid === accountUuid
  );

  const reports = trpc.insightsReports.getManyInsightsReports.useQuery(
    { insightsAdAccountFacebookUuid: accountUuid },
    { enabled: !!user.data && !!accountUuid }
  );

  const [report, setReport] =
    useState<ArrayElement<GetManyInsightsReportsResponse> | null>(null);
  const [showRenameReportModal, setShowRenameReportModal] = useState(false);
  const [showDuplicateReportModal, setShowDuplicateReportModal] =
    useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);

  const onCloseDuplicateModal = () => {
    setShowDuplicateReportModal(false);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteReportModal(false);
  };

  return (
    <>
      <Sidebar
        side="left"
        className="flex h-full w-full flex-col justify-between gap-6 overflow-y-auto bg-white p-5"
      >
        <Sidebar.Content className="min-h-0">
          <Sidebar.Section
            title={`${LL.insights.sidebar.facebook.title()} (${
              facebookAdAccounts.data?.length ?? 0
            })`}
          >
            {facebookAdAccounts.isLoading && (
              <SquareLoaders amount={5} className="h-11" />
            )}

            {!facebookAdAccounts.isLoading &&
              facebookAdAccounts.data &&
              facebookAdAccounts.data?.length === 0 && (
                <div className="text-xs text-purple-800/40">
                  {LL.insights.sidebar.facebook.noAccounts()}
                </div>
              )}

            {!facebookAdAccounts.isLoading &&
              facebookAdAccounts.data &&
              facebookAdAccounts.data.length > 0 &&
              currentAdAccount && (
                <>
                  <div className="pb-2">
                    <DialogPopover>
                      <AriaButton
                        className="grow-1 flex w-full flex-row justify-between font-semibold"
                        variant="secondary"
                      >
                        <span className="truncate">
                          {currentAdAccount.name}
                        </span>
                        <Icon className="h-5 w-5 shrink-0">
                          <ChevronSelectorVertical />
                        </Icon>
                      </AriaButton>
                      <div className="relative flex w-72 flex-col gap-2 p-2">
                        <div
                          className={
                            'relative flex max-h-[180px] flex-col overflow-auto'
                          }
                        >
                          <AriaMenu.List
                            selectionMode="single"
                            selectedKeys={[currentAdAccount.uuid]}
                            onSelectionChange={(key) => {
                              if (key instanceof Set) {
                                const value = key.values().next().value;
                                if (!value) {
                                  return;
                                }
                                navigate(
                                  `/insights/accounts/${
                                    key.values().next().value
                                  }`
                                );
                              }
                            }}
                          >
                            {facebookAdAccounts.data
                              ?.sort((a, b) => a.name.localeCompare(b.name))
                              .map((facebookAdAccount) => (
                                <AriaMenu.Item
                                  key={facebookAdAccount.uuid}
                                  id={facebookAdAccount.uuid}
                                  showCheckbox={true}
                                  className="overflow-hidden"
                                  contentClassName="truncate"
                                >
                                  <Text slot="label">
                                    {facebookAdAccount.name}
                                  </Text>
                                </AriaMenu.Item>
                              ))}
                          </AriaMenu.List>
                        </div>
                        <FacebookConnect
                          data-intercom-target="insight_accounts"
                          className="w-full text-xs font-semibold"
                          variant="secondary"
                        >
                          <span>{LL.insights.sidebar.facebook.cta()}</span>
                        </FacebookConnect>
                      </div>
                    </DialogPopover>
                  </div>
                  <Sidebar.NavLink
                    end
                    to={`/insights/accounts/${currentAdAccount.uuid}`}
                  >
                    <Icon>
                      <BarChartSquare02 />
                    </Icon>
                    {LL.insights.sidebar.facebook.overview()}
                  </Sidebar.NavLink>
                  {copilotEnabled && (
                    <Sidebar.NavLink
                      to={`/insights/analysis/${currentAdAccount.uuid}`}
                    >
                      <Icon>
                        <Target05 />
                      </Icon>
                      {LL.insights.sidebar.facebook.analysis()}
                    </Sidebar.NavLink>
                  )}
                </>
              )}
          </Sidebar.Section>

          {facebookIntegrations.length > 0 && accountUuid && (
            <Sidebar.Section
              title={LL.insights.sidebar.reports.title()}
              className="grow overflow-hidden"
              contentClassName="overflow-y-auto"
            >
              {reports.isLoading && (
                <SquareLoaders amount={5} className="h-11" />
              )}

              {!reports.isLoading &&
                (reports.data == null || reports.data?.length === 0) && (
                  <div className="text-xs text-purple-800/40">No reports</div>
                )}

              {!reports.isLoading &&
                reports.data &&
                reports.data.length > 0 && (
                  <div className="overflow-y-auto">
                    {reports.data?.map((report) => {
                      const active = reportUuid === report.uuid;
                      return (
                        <Sidebar.Button
                          className={classNames(
                            'group w-full disabled:text-purple-200 disabled:hover:bg-white',
                            active && 'bg-purple-100'
                          )}
                          key={report.uuid}
                          onClick={() => {
                            navigate(
                              `/insights/reports/${report.insightsAdAccountFacebookUuid}/${report.uuid}`
                            );
                          }}
                        >
                          <div className="flex w-full flex-row items-center justify-between gap-2.5">
                            <div className="flex h-full w-full flex-row items-center gap-2 overflow-hidden">
                              <Icon className="flex-centered flex h-5 w-5 shrink-0 group-disabled:stroke-purple-200">
                                <FolderReport />
                              </Icon>
                              <span className="truncate">{report.name}</span>
                            </div>
                            <div className="invisible">
                              <AriaMenu>
                                <Button className="group/report-options flex items-center justify-center rounded outline-none">
                                  <span className="flex h-5 w-5 items-center justify-center rounded bg-none text-text-secondary group-hover/report-options:bg-purple-200">
                                    <Icon className="h-4 w-4">
                                      <DotsHorizontal />
                                    </Icon>
                                  </span>
                                </Button>
                                <AriaMenu.List
                                  onAction={(key) => {
                                    if (key === 'rename') {
                                      setReport(report);
                                      setShowRenameReportModal(true);
                                    } else if (key === 'duplicate') {
                                      setReport(report);
                                      setShowDuplicateReportModal(true);
                                    } else if (key === 'delete') {
                                      setReport(report);
                                      setShowDeleteReportModal(true);
                                    }
                                  }}
                                >
                                  <AriaMenu.Item
                                    id="rename"
                                    icon={
                                      <Icon>
                                        <Edit01 />
                                      </Icon>
                                    }
                                  >
                                    {LL.insights.sidebar.reports.menu.rename()}
                                  </AriaMenu.Item>
                                  <AriaMenu.Item
                                    id="duplicate"
                                    icon={
                                      <Icon>
                                        <Copy01 />
                                      </Icon>
                                    }
                                  >
                                    {LL.insights.sidebar.reports.menu.duplicate()}
                                  </AriaMenu.Item>
                                  <AriaMenu.Item
                                    id="delete"
                                    icon={
                                      <Icon>
                                        <Trash01 />
                                      </Icon>
                                    }
                                    danger={true}
                                  >
                                    {LL.insights.sidebar.reports.menu.delete()}
                                  </AriaMenu.Item>
                                </AriaMenu.List>
                              </AriaMenu>
                            </div>
                          </div>
                        </Sidebar.Button>
                      );
                    })}
                  </div>
                )}
            </Sidebar.Section>
          )}
          {slackEnabled && currentAdAccount && (
            <Sidebar.Section title="Manage">
              <Sidebar.NavLink
                data-intercom-target="insight_slack"
                to={`/insights/schedules/${currentAdAccount.uuid}`}
              >
                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <span className="flex flex-row items-center gap-2 text-sm font-semibold text-primary">
                    <Icon className="h-5 w-5">
                      <SlackGradient />
                    </Icon>
                    <span>
                      {LL.insights.summarySchedules.sidebar.navLinkPrimaryText()}
                    </span>
                  </span>
                </div>
              </Sidebar.NavLink>

              <Sidebar.NavLink
                to={`/insights/accounts/${currentAdAccount.uuid}/settings`}
              >
                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <span className="flex flex-row items-center gap-2 text-sm font-semibold text-primary">
                    <Icon className="h-5 w-5">
                      <Settings01 />
                    </Icon>
                    <span>Account & Data Settings</span>
                  </span>
                </div>
              </Sidebar.NavLink>
            </Sidebar.Section>
          )}
        </Sidebar.Content>
      </Sidebar>

      {report && (
        <RenameReportModal
          reportUuid={report.uuid}
          reportName={report.name}
          show={showRenameReportModal}
          onClose={() => setShowRenameReportModal(false)}
        />
      )}
      {report && (
        <DuplicateReportModal
          reportUuid={report.uuid}
          reportName={report.name}
          show={showDuplicateReportModal}
          onClose={onCloseDuplicateModal}
        />
      )}
      {report && (
        <DeleteReportModal
          reportUuid={report.uuid}
          reportName={report.name}
          show={showDeleteReportModal}
          onClose={onCloseDeleteModal}
        />
      )}
    </>
  );
};

export default InsightsSidebar;
