import { useCallback, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { ArrayElement, DirectoryItemType } from '@magicbrief/common';
import { Icon } from 'src/components/Icon';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import Loading02 from 'src/assets/svgicons/line/loading-02.svg';
import {
  ComboboxInput,
  ComboboxOption,
  ComboboxOptionLabel,
  ComboboxOptions,
  ComboboxTransition,
} from 'src/components/Select/ComboboxContent';
import { trpc } from 'src/lib/trpc';
import Tag from 'src/components/LibraryDetailsView/components/LibraryDetailsSidebar/components/Tag/Tag';
import { Alert } from 'src/components/Alert';
import {
  useAddEntitiesToDirectory,
  useCreateDirectory,
  useDeleteNodesFromDirectory,
} from 'src/utils/useDirectories';

type Props = {
  entityType: DirectoryItemType;
  entityUuid: string;
  proxyEntityType?: 'PlatformAd' | null;
  onSuccess?: Exclude<
    Parameters<typeof useAddEntitiesToDirectory>[0],
    undefined
  >['onSuccess'];
};

const EntityDirectoryManagement: React.FunctionComponent<Props> = ({
  entityType,
  entityUuid,
  proxyEntityType,
  onSuccess,
}): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const resetSearch = useCallback(() => setSearchQuery(''), []);

  const directories =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  const flatDirectoryList = directories.data?.directories
    ? Object.values(directories.data?.directories)
    : [];

  const nodes = trpc.directories.getDirectoryNodesAliasedToEntity.useQuery(
    {
      entityType,
      entityUuid,
    },
    { enabled: !proxyEntityType }
  );

  const createDirectory = useCreateDirectory();
  const addEntityToDirectory = useAddEntitiesToDirectory({ onSuccess });

  const filteredDirectories = flatDirectoryList.filter(
    (x) =>
      x.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) &&
      !nodes.data?.find((y) => y.ParentDirectoryNode?.uuid === x.uuid)
  );

  if (nodes.error) {
    return (
      <Alert type="error">We couldn&apos;t retrieve the list of boards</Alert>
    );
  }

  return (
    <div className="flex flex-col ">
      <Combobox<ArrayElement<typeof flatDirectoryList> | null>
        value={null}
        multiple={false}
        onChange={(selected) => {
          if (selected) {
            if (typeof selected === 'string') {
              createDirectory.mutate({
                name: selected,
                withEntities: [
                  {
                    entityType: proxyEntityType ?? entityType,
                    entityUuid,
                  },
                ],
              });
            } else {
              addEntityToDirectory.mutate({
                destinationDirectoryNodeUuid: selected.uuid,
                entities: [
                  {
                    entityType: proxyEntityType ?? entityType,
                    entityUuid,
                  },
                ],
              });
            }
          }
        }}
      >
        <ComboboxTransition onHide={resetSearch}>
          <div className="relative">
            <ComboboxInput
              displayValue={() => ''}
              placeholder="Search or create a board..."
              handleQueryChange={(ev) => {
                setSearchQuery(ev.target.value);
              }}
            />
          </div>
          <div className="absolute w-full">
            <ComboboxOptions>
              {filteredDirectories?.map((x) => {
                return (
                  <ComboboxOption key={x.uuid} value={x}>
                    {({ selected }) => (
                      <ComboboxOptionLabel selected={selected} disabled={false}>
                        {x.name}
                      </ComboboxOptionLabel>
                    )}
                  </ComboboxOption>
                );
              })}
              {searchQuery && (
                <ComboboxOption
                  key="--mb-new-directory-button--"
                  value={searchQuery}
                >
                  <ComboboxOptionLabel selected={false} disabled={false}>
                    {`Create new board "${searchQuery}"`}
                  </ComboboxOptionLabel>
                </ComboboxOption>
              )}
            </ComboboxOptions>
          </div>
        </ComboboxTransition>
      </Combobox>
      {!!nodes.data?.length && (
        <div className="flex flex-row flex-wrap gap-3 pt-3">
          {nodes.data.map((x) => (
            <DirectoryNodePill
              key={x.uuid}
              name={x.ParentDirectoryNode?.name}
              uuid={x.uuid}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DirectoryNodePill: React.FC<{
  name: string | undefined | null;
  uuid: string;
}> = ({ name, uuid }) => {
  const deleteNode = useDeleteNodesFromDirectory();

  return (
    <Tag>
      <div className="flex min-w-0 flex-row items-center gap-3">
        <div className="truncate">{name ?? 'Untitled Board'}</div>
        {deleteNode.isLoading ? (
          <Icon className="text-primary animate-spin h-4 w-4">
            <Loading02 />
          </Icon>
        ) : (
          <button
            onClick={() =>
              deleteNode.mutate({
                directoryNodeUuids: [uuid],
              })
            }
            className="cursor-pointer appearance-none text-primary"
          >
            <Icon className="h-4 w-4">
              <XClose />
            </Icon>
          </button>
        )}
      </div>
    </Tag>
  );
};

export default EntityDirectoryManagement;
