import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { getAttributionData } from '../../../lib/attribution';
import { segment } from '../../../lib/segment';
import { useUserAndOrganisation } from '../../../utils/useUserAndOrganisation';
import { useFirebaseContext } from '../FirebaseOutlet/useFirebaseContext';

const AnalyticsOutlet: React.FunctionComponent = (): JSX.Element => {
  /** @todo fix this premature call - if a user creates an account, this will fire before
   * we have initialised them and result in a race condition error (not fatal)
   * we could make getUserInfo + initialise a single idempotent endpoint also
   * */
  const location = useLocation();
  const user = useUserAndOrganisation();
  const firebaseUser = useFirebaseContext();

  const firebaseID = firebaseUser?.uid;

  useEffect(() => {
    const data = getAttributionData() || {};

    if (user.data) {
      if (window.Intercom) {
        window.Intercom('update', {
          email: user.data.user.email,
          name: user.data.user.name,
        });
      }
      const fullName = user.data.user.name.split(' ');
      const firstName = fullName[0];
      const lastName = fullName.length > 1 ? fullName.slice(1).join(' ') : '';

      void segment?.identify(user.data.user.uuid, {
        name: user.data.user.name,
        email: user.data.user.email,
        firebaseID,
        funnelState: user.data.planStatus,
        createdAt: user.data.user.createdAt,
        first_name: firstName, // added first name
        last_name: lastName,
        ...data,
      });
    }
  }, [user.data, firebaseID]);

  useEffect(() => {
    void segment?.page({});
  }, [location]); // Triggered by changes to the location

  return <Outlet />;
};

export default AnalyticsOutlet;
