import { createContext, PropsWithChildren, useState } from 'react';

type BillingFrequency = 'monthly' | 'yearly';

type UpgradeModalV2ContextState = {
  isOpen: boolean;
  close: () => void;
  prompt?: string;
  billingFrequency: BillingFrequency;
  setBillingFrequency: React.Dispatch<React.SetStateAction<BillingFrequency>>;
  isConfirmUpgradeOpen: boolean;
  setIsConfirmUpgradeOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UpgradeModalV2Context = createContext<UpgradeModalV2ContextState>({
  isOpen: false,
  close: () => {},
  prompt: '',
  billingFrequency: 'monthly',
  setBillingFrequency: () => {},
  isConfirmUpgradeOpen: false,
  setIsConfirmUpgradeOpen: () => {},
});

interface UpgradeModalV2ContextProps extends PropsWithChildren {
  isOpen: boolean;
  close: () => void;
  prompt?: string;
}

const UpgradeModalV2Provider = ({
  isOpen,
  close,
  prompt,
  children,
}: UpgradeModalV2ContextProps) => {
  const [billingFrequency, setBillingFrequency] =
    useState<BillingFrequency>('monthly');
  const [isConfirmUpgradeOpen, setIsConfirmUpgradeOpen] = useState(false);

  return (
    <UpgradeModalV2Context.Provider
      value={{
        isOpen,
        close,
        prompt,
        billingFrequency,
        setBillingFrequency,
        isConfirmUpgradeOpen,
        setIsConfirmUpgradeOpen,
      }}
    >
      {children}
    </UpgradeModalV2Context.Provider>
  );
};

export { UpgradeModalV2Context, UpgradeModalV2Provider };
