import { FC, ReactNode, useState } from 'react';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { isRoleAllowed } from '@magicbrief/server/src/permissions/utils/is-role-allowed';
import { getAllAdAccounts, login } from 'src/lib/facebook';
import Facebook from 'src/assets/svgicons/brands/Facebook.svg';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { AriaButton, ButtonVariants } from '../Button/Button';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import { trpc } from '../../lib/trpc';
import {
  FacebookAdAccountSelectModal,
  PendingAuthState,
} from './FacebookAdAccountSelect';

type Props = {
  onComplete?: () => void;
  className?: string;
  variant?: ButtonVariants;
  icon?: ReactNode;
  children?: ReactNode;
};

export const FacebookConnect: FC<Props> = ({
  onComplete,
  className,
  variant,
  icon = (
    <Icon>
      <Facebook />
    </Icon>
  ),
  children,
}) => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();

  const [pendingAuthState, setPendingAuthState] =
    useState<PendingAuthState | null>(null);

  const user = useUserAndOrganisation();
  const permission =
    trpc.permissions.getCurrentUserPermissionForEntity.useQuery(
      {
        entityType: 'Organisation',
        entityUuid: user.data?.organisation.uuid ?? '',
      },
      {
        enabled: !!user.data,
      }
    );

  const isAllowed = permission.data?.role
    ? isRoleAllowed(permission.data.role, 'editor')
    : false;

  const facebookAdAccounts = trpc.insights.getManyFacebookAdAccounts.useQuery(
    undefined,
    { enabled: !!user.data?.hasFacebookIntegrations }
  );

  const authenticate = async () => {
    try {
      const { fbUserID, accessToken } = await login();

      const adAccounts = await getAllAdAccounts();

      setPendingAuthState({
        fbUserID,
        accessToken,
        adAccounts,
        currentIntegrations: user.data?.facebookIntegrations ?? [],
      });
    } catch (e) {
      captureException(e);
      toast.error(
        LL.integrations.facebook.authenticateFacebook.error({
          error:
            e instanceof Error && 'message' in e ? e.message : 'Unknown error',
        }),
        { className: 'toast-danger' }
      );
    }
  };

  const isLoading =
    user.isFetching || permission.isFetching || facebookAdAccounts.isFetching;

  return (
    <>
      {user.data && (
        <FacebookAdAccountSelectModal
          pendingAuthState={
            pendingAuthState
              ? {
                  ...pendingAuthState,
                  currentIntegrations: user.data.facebookIntegrations,
                }
              : null
          }
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setPendingAuthState(null);
              void trpcUtils.user.getUserAndOrganisation.invalidate();
              onComplete?.();
            }
          }}
        />
      )}
      <AriaButton
        className={classNames(className)}
        onPress={authenticate}
        loading={isLoading}
        isDisabled={!isAllowed}
        icon={icon}
        variant={variant}
      >
        {children
          ? children
          : user.data?.hasFacebookIntegrations
          ? LL.integrations.facebook.connectFacebook.ctaModify()
          : LL.integrations.facebook.connectFacebook.ctaConnect()}
      </AriaButton>
      {!isAllowed && (
        <p className="text-xs">{LL.integrations.notAllowedNote()}</p>
      )}
    </>
  );
};
