import { forwardRef, Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

type FullscreenThreshold = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'always';

export type BaseModalProps = React.PropsWithChildren<{
  /**
   * Whether the Modal is open or not.
   */
  show: boolean;
  /**
   * Called when the Modal is dismissed via an outside click or by pressing Escape.
   * Typically used to close the dialog by setting `open` to false.
   *
   * Set this to null or undefined to prevent closure of the Modal via the Escape key or outside clicks.
   */
  onClose: (() => void) | null;
  /**
   * Whether the appear transition should run on initial mount. False by default.
   * Set to true when you both mount and show the modal at the same time, rather than
   * mounting _then_ setting `show` to `true`.
   */
  appear?: boolean;
  /**
   * A ref to an element that should receive focus when the Modal opens.
   */
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  /**
   * CSS classes to be applied to the outer part of the Modal body.
   */
  wrapperClassName?: string;
  /**
   * CSS classes to be applied to the inner-most part of the Modal body (`Dialog.Panel`).
   */
  panelClassName?: string;
  /**
   * Whether the element should ignore the internally managed open/closed state.
   */
  staticState?: boolean;
  /**
   * Called when the Modal's exit transition has completed.
   */
  afterClose?: () => void;
  /**
   * Custom overlay classname to override the default background colour and opacity.
   */
  overlayClassName?: string;
  /**
   * At what threhsold the modal should stretch to fill the entire viewport, or not at all. False by default.
   */
  fullscreenThreshold?: FullscreenThreshold | false;
  /**
   * Constrains the maxmimum possible height of the modal to the size of the viewport.
   * False by default. Set to true when you want your modal to scroll internally rather
   * than on the page.
   */
  constrainToViewport?: boolean;
}> &
  React.HTMLAttributes<HTMLDivElement>;

const baseFullscreenPanelStyle = 'grow';
const fullscreenPanelThresholdStyles = (
  threshold: FullscreenThreshold,
  constrainToViewport: boolean
): string => {
  return {
    xs: `${baseFullscreenPanelStyle} xs:grow-0 ${
      constrainToViewport ? 'xs:h-screen' : 'xs:h-auto'
    }`,
    sm: `${baseFullscreenPanelStyle} sm:grow-0 ${
      constrainToViewport ? 'sm:h-screen' : 'sm:h-auto'
    }`,
    md: `${baseFullscreenPanelStyle} md:grow-0 ${
      constrainToViewport ? 'md:h-screen' : 'md:h-auto'
    }`,
    lg: `${baseFullscreenPanelStyle} lg:grow-0 ${
      constrainToViewport ? 'lg:h-screen' : 'lg:h-auto'
    }`,
    xl: `${baseFullscreenPanelStyle} xl:grow-0 ${
      constrainToViewport ? 'xl:h-screen' : 'xl:h-auto'
    }`,
    always: baseFullscreenPanelStyle,
  }[threshold];
};

const baseFullscreenWrapperStyle = 'items-stretch p-0';
const fullscreenWrapperThresholdStyles = (
  threshold: FullscreenThreshold,
  constrainToViewport: boolean
): string => {
  return {
    xs: `${baseFullscreenWrapperStyle} xs:items-center xs:p-4 ${
      constrainToViewport ? 'xs:h-screen' : 'xs:h-auto'
    }`,
    sm: `${baseFullscreenWrapperStyle} sm:items-center sm:p-4 ${
      constrainToViewport ? 'sm:h-screen' : 'sm:h-auto'
    }`,
    md: `${baseFullscreenWrapperStyle} md:items-center md:p-4 ${
      constrainToViewport ? 'md:h-screen' : 'md:h-auto'
    }`,
    lg: `${baseFullscreenWrapperStyle} lg:items-center lg:p-4 ${
      constrainToViewport ? 'lg:h-screen' : 'lg:h-auto'
    }`,
    xl: `${baseFullscreenWrapperStyle} xl:items-center xl:p-4 ${
      constrainToViewport ? 'xl:h-screen' : 'xl:h-auto'
    }`,
    always: baseFullscreenWrapperStyle,
  }[threshold];
};

/**
 * @deprecated Use `AriaModal` or `BaseModal` from `components/AriaModal/BaseModal` instead.
 */
const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>(
  (
    {
      show,
      children,
      onClose,
      panelClassName,
      initialFocus,
      staticState,
      afterClose,
      overlayClassName,
      fullscreenThreshold = false,
      constrainToViewport = false,
      className,
      wrapperClassName,
      appear = false,
      ...divProps
    },
    ref
  ) => {
    const onCloseCallback = useCallback(() => {
      if (onClose) {
        onClose();
      }
    }, [onClose]);

    return (
      <Transition appear={appear} show={show} as={Fragment}>
        <Dialog
          static={staticState}
          initialFocus={initialFocus}
          as="div"
          className={classNames('relative z-[9999]', className)}
          onClose={onCloseCallback}
          ref={ref}
          {...divProps}
        >
          <Transition.Child
            as={Fragment}
            afterLeave={afterClose}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                'fixed inset-0 transition-opacity',
                overlayClassName || 'bg-gray-900 bg-opacity-60'
              )}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-400 overflow-y-auto">
            <div
              className={classNames(
                'flex min-h-full flex-col justify-center',
                fullscreenThreshold
                  ? fullscreenWrapperThresholdStyles(
                      fullscreenThreshold,
                      constrainToViewport
                    )
                  : 'items-center p-4 sm:p-0',
                constrainToViewport ? 'h-screen' : '',
                wrapperClassName
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    'relative transform shadow-xl transition-all',
                    fullscreenThreshold
                      ? fullscreenPanelThresholdStyles(
                          fullscreenThreshold,
                          constrainToViewport
                        )
                      : '',
                    constrainToViewport ? 'max-h-screen' : '',
                    panelClassName
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

BaseModal.displayName = 'BaseModal';
export default BaseModal;
