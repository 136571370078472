import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import StatusResponse = facebook.StatusResponse;

/** @see {@link https://developers.facebook.com/docs/graph-api/guides/error-handling/} */
type FBError = {
  message: string;
  type: string;
  code: number;
  error_subcode: number;
  error_user_title: string;
  error_user_msg: string;
  fbtrace_id: string;
};

/** @see {@link https://developers.facebook.com/docs/graph-api/results/} */
type FBPaging = {
  cursors: {
    before: string;
    after: string;
  };
  previous: string;
  next: string;
};

type FBResponse<T> = {
  data?: T;
  paging?: FBPaging;
  error?: FBError;
};

export const init = async () => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: getFeatureFlagValue('FACEBOOK_APP_ID'),
      xfbml: true,
      version: getFeatureFlagValue('FACEBOOK_API_VERSION'),
    });
  };
};

export const login = async (): Promise<{
  fbUserID: string;
  accessToken: string;
  expiresAt: number;
}> => {
  return new Promise((resolve, reject) => {
    try {
      FB.login(
        (response: StatusResponse) => {
          switch (response.status) {
            case 'connected':
              break;
            case 'authorization_expired':
              throw new Error('Error logging in, authorization has expired');
            case 'not_authorized':
              throw new Error('Error logging in, user is not authorized');
            case 'unknown':
              throw new Error('Error logging in, unknown status received');
            default:
              throw new Error('Error logging in, user is not connected');
          }

          const {
            userID: fbUserID,
            accessToken,
            data_access_expiration_time: expiresAt,
          } = response.authResponse;

          if (accessToken == null) {
            throw new Error('No access token received');
          } else if (expiresAt == null) {
            throw new Error('No expiry for token received');
          }

          resolve({
            fbUserID,
            accessToken,
            expiresAt,
          });
        },
        {
          config_id: getFeatureFlagValue('FACEBOOK_APP_LOGIN_CONFIG_ID'),
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

export type AdAccount = {
  accountId: string;
  name: string;
};

type GetAdAccountsResponse = FBResponse<
  {
    account_id: string;
    name: string;
  }[]
>;

export const getAdAccounts = async ({
  userId = 'me',
  limit = 25,
  after = undefined,
}: {
  userId: string;
  limit?: number;
  after?: string;
}): Promise<GetAdAccountsResponse> => {
  return new Promise((resolve, reject) => {
    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append('fields', 'account_id,name');
      params.append('limit', `${limit}`);

      if (after) {
        params.append('after', `${after}`);
      }

      FB.api(
        `/${userId}/adaccounts?${params.toString()}`,
        async (response: GetAdAccountsResponse) => {
          if (response.error) {
            throw new Error(
              response.error.message ?? 'Error getting ad accounts'
            );
          }

          if (response.data == null) {
            throw new Error('No data returned');
          }

          resolve(response);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

function mapAdAccountResponse(response: GetAdAccountsResponse): AdAccount[] {
  const result =
    response.data?.map<AdAccount>((datum) => ({
      accountId: datum.account_id,
      name: datum.name,
    })) ?? [];

  return result;
}

export async function getAllAdAccounts(userId = 'me'): Promise<AdAccount[]> {
  let result: AdAccount[] = [];
  let response = await getAdAccounts({ userId });

  result = mapAdAccountResponse(response);

  while (response.paging?.cursors.after) {
    response = await getAdAccounts({
      userId,
      after: response.paging?.cursors.after,
    });

    result = [...result, ...mapAdAccountResponse(response)];
  }

  return result.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}
