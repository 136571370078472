import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { appConfigs } from 'src/configs';
import { auth } from './firebase';

/** @todo remove when we migrate endpoint */
const getUrl = () => {
  const betaUrl = localStorage.getItem('beta:MB_API_URL');
  return betaUrl != null ? `${betaUrl}/api/v1` : appConfigs.host ?? '';
};

const instance = axios.create({
  baseURL: getUrl(),
});

instance.interceptors.request.use(async (config) => {
  /**
   * Ask Firebase app instance for token. This
   * should always return the current token or a new
   * token if the cached one is expiring.
   *
   * @see https://firebase.google.com/docs/reference/js/auth.user.md#usergetidtoken
   */
  const firebaseToken = await auth.currentUser?.getIdToken();
  if (!firebaseToken) {
    Sentry.captureException(
      new Error('No Firebase token received by Axios request interceptor'),
      (scope) => {
        scope.setLevel('error');
        scope.setExtras({
          requestURL: config.url,
        });
        scope.setUser({
          id: auth.currentUser?.uid,
          email: auth.currentUser?.email || undefined,
        });
        return scope;
      }
    );
  }
  const authToken = `Bearer ${firebaseToken}`;
  config.headers = {
    ...config.headers,
    Authorization: authToken,
  };
  return config;
});

const handleExpiredToken = instance.interceptors.response.use(
  (res) => res,
  async function (error: AxiosError) {
    if (error?.response?.status === 403) {
      if (auth.currentUser) {
        const newToken = await auth.currentUser.getIdToken(true);

        if (newToken) {
          error.config.headers = {
            ...error.config.headers,
            Authorization: `Bearer ${newToken}`,
          };
          return axios.request(error.config);
        } else {
          Sentry.captureException(
            new Error(
              'Axios one-time 403 retry handler failed as new token could not be fetched from Firebase'
            ),
            (scope) => {
              scope.setLevel('error');
              scope.setUser({
                id: auth.currentUser?.uid,
                email: auth.currentUser?.email || undefined,
              });
              return scope;
            }
          );
        }
      } else {
        Sentry.captureException(
          new Error(
            'Axios one-time 403 retry handler failed as auth.currentUser was null'
          ),
          (scope) => {
            scope.setLevel('error');
            return scope;
          }
        );
      }
    }

    instance.interceptors.response.eject(handleExpiredToken);

    return Promise.reject(error);
  }
);

export default instance;
